import './App.scss';
import React, { useState } from 'react';
import Header from './components/header/header';
import Book from './components/book/book';
import Main from './components/main/main';
import BookFooter from './components/book-footer/book-footer';
import Footer from './components/footer/footer';
import 'semantic-ui-css/semantic.min.css'

const App = () => {
  const [active, setActive] = useState(0)
  return (
    <div className="app">
      <div className='wrapper'>
        <div className="container">
          <Header />
          <Book active={active} setActive={setActive} />
        </div>
      </div>
      <div className='delimiter' />
      <div className="container">
        <Main />
      </div>

      <BookFooter active={active}/>
      <Footer />
    </div>
  );
}

export default App;
