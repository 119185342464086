import React, { useRef, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import Pdf from '@mikecousins/react-pdf';
import pdf from '../../assets/DeFi_Book_Final_Color-slice.pdf'
import './style.scss'
import { CloseIcon, LeftArrowIcon, RightArrowIcon } from '../../assets/icons';

const PdfModal = ({open, setOpen}) => {
  const canvasRef = useRef(null);
  const [page, setPage] = useState(1);

  return (
    <Modal
      className='modal'
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <div className='pdf'>
        <div className='pdf-title'>Preview</div>
        <div className='pdf-close' onClick={() => setOpen(false)}>
          <CloseIcon/>
        </div>
        <div className='pdf-view'>
          <Pdf file={pdf} page={page} scale={1.3}>
            {({ pdfDocument, pdfPage, canvas }) => (
              <>
                {canvas}
                {Boolean(pdfDocument && pdfDocument.numPages) && (
                  <div className='pdf-control'>
                    <div className='pdf-control-counter'>
                      Page <span>{page}</span> of {pdfDocument.numPages}
                    </div>

                    <div className='pdf-control-action'>
                      <button
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                      >
                        <LeftArrowIcon />
                      </button>
                      <button
                        disabled={page === pdfDocument.numPages}
                        onClick={() => setPage(page + 1)}
                      >
                        <RightArrowIcon/>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Pdf>
        </div>
      </div>

    </Modal>
  );
};

export default PdfModal;
