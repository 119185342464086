import React from 'react';
import { MediumIcon, SmallLogo, TelegramIcon, TwitterIcon, YoutubeIcon } from '../../assets/icons';
import './style.scss'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer_social">
        <a target='_blank' href="https://t.me/dedotfi"><TelegramIcon/></a>
        <a target='_blank' href="https://twitter.com/dedotfi"><TwitterIcon/></a>
        <a target='_blank' href="https://blog.de.fi/"><MediumIcon /></a>
        <a target='_blank' href="https://www.youtube.com/channel/UCMCGy1ExKesm8YlcKGAFRGA"><YoutubeIcon /></a>
      </div>

      <div className='footer_link-home'>
        <a href="https://de.fi/"><SmallLogo /> Go to the homepage  → </a>
      </div>
    </div>
  );
};

export default Footer;
