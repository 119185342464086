import React from 'react';
import { LogoEdu, MediumIcon, TelegramIcon, TwitterIcon, YoutubeIcon } from '../../assets/icons';
import './style.scss'
import { isMobile } from 'react-device-detect';

const Header = () => {

  return (
    <div className='header'>
      <a href='/' className="header_logo"><LogoEdu /></a>
      <div className="header_social">
        {!isMobile && <a target='_blank' href="https://t.me/dedotfi"><TelegramIcon/></a>}
        <a target='_blank' href="https://twitter.com/dedotfi"><TwitterIcon/></a>
        <a target='_blank' href="https://blog.de.fi/"><MediumIcon /></a>
        <a target='_blank' href="https://www.youtube.com/channel/UCMCGy1ExKesm8YlcKGAFRGA"><YoutubeIcon /></a>
      </div>
    </div>
  );
};

export default Header;
