import React, { useState } from 'react';
import './style.scss';
import book from '../../assets/book.png'
import { FbShareIcon, LabelNew, TgShareIcon, TwShareIcon } from '../../assets/icons';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import PdfModal from '../pdf-modal/pdf-modal';
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

const buttons = ['PAPERBACK', 'E-BOOK']

const Book = ({active, setActive}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className='book'>
      <PdfModal open={open} setOpen={setOpen}/>
      <div className="book_column">
        {isMobile && (
          <h1 className="book_title">
            DeFi Ultimate Guide <span> — <br/> The Wall Street Era is Over</span>
          </h1>
        )}
        <div className="book_column-left">
          <div className='book_label'><LabelNew /></div>
          <img className='book_img' src={book} alt="book"/>
          <div className='book_button-wrapper'>
            <button onClick={() => setOpen(true)}>Watch preview</button>
          </div>
          <span className='book_pages'>200 PAGES</span>
        </div>
        <div className="book_column-right">
          {!isMobile && (
            <h1 className="book_title">
              DeFi Ultimate Guide <span> — The Wall Street Era is Over</span>
            </h1>
          )}

          <span className="book_desc">
            by DE.FI <a target='_blank' href="https://de.fi">App</a>
          </span>

          <span className='book_text'>
            Struggling to understand DeFi? Everything you need to know about Decentralized Finance is here.  Finally a book that breaks downs the fundamentals of DeFi, to get you started on your journey. Learn about how DeFi started, memes, types of investors to the top DeFi protocols you will want to know about.
          </span>
          <span className='book_text book_text-last'>
            Whether you're a beginner, novice or experienced Yield Farmer, this book is for you and will fill in the gaps you've been missing but eager to know about.
          </span>

          <div className='book_buttons-wrapper'>
            <div className='book_buttons'>
              {buttons.map((button, id) => (
                <button className={classNames({
                  'buttonActive': id === active
                })} onClick={() => setActive(id)}>{button}</button>
              ))}
            </div>

            <div className='book_price'>{active === 0 ? '$9.70' : '$6.50'}</div>
          </div>

          <div className='book_big-button-wrapper'>
            <a
              target='_blank'
              href={
              active === 0 ? 'https://www.amazon.com/Wall-Street-Over-Cryptocurrency-Decentralized/dp/1527295362/' : 'https://www.amazon.com/Wall-Street-Over-Cryptocurrency-Decentralized-ebook/dp/B094X2BJH9'
            }
              className='book_big-button'
            >
              Get Your Copy
            </a>
          </div>

          <div className="book_share">
            <FacebookShareButton quote={'I recommend everyone in DeFi to read The DeFi Ultimate Book - The Wall Street Era is Over by DE.FI'} hashtag={'#de.fi, #de.fi_community'} url={'https://defibook.io/'}>
              <div className="book_share-button">
                <FbShareIcon />
                Share
              </div>
            </FacebookShareButton>
            <TwitterShareButton title={'I recommend everyone in DeFi to read The DeFi Ultimate Book - The Wall Street Era is Over by DE.FI'} via={'@Wendel Pereira'} hashtags={['de.fi', 'de.fi_community']} url={'https://defibook.io/'}>
              <div className="book_share-button">
                <TwShareIcon/>
                Tweet
              </div>
            </TwitterShareButton>
            <TelegramShareButton title={'I recommend everyone in DeFi to read The DeFi Ultimate Book - The Wall Street Era is Over by DE.FI'} via={'@Wendel Pereira'} hashtags={['de.fi', 'de.fi_community']} url={'https://defibook.io/'}>
              <div className="book_share-button">
                <TgShareIcon />
                Telegram
              </div>
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
