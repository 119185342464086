import React, { useState } from 'react';
import './style.scss'
import { ArrowIcon, BigLogo, LogoMain } from '../../assets/icons';
import photo from '../../assets/foto.png'
import classNames from 'classnames';

const Main = () => {
  const [more, setMore] = useState(true)
  return (
    <div className='main'>

      <div className="main_from-author">
        <div className="main_from-author_header">
          <div className="main_from-author_title">From the Author</div>
          <div className="main_from-author_logo"><LogoMain /></div>
        </div>

        {more ? (
          <span className='main_from-author_text'>
            There were a couple of reasons why we set out to write this book. <br/>
            Firstly,<strong className='main_from-author_text-strong'> as experts who had been in DeFi (Decentralized Finance) since day one</strong>, we could see interest in it was growing and therefore lots of new entrants would be looking for trustworthy sources of information <br/>
            to learn from. <br/>
            <span className='main_from-author_text-padding'>
              Secondly, we knew from our own experiences that there are malicious actors waiting to prey on unsuspecting victims in DeFi and we wanted to help as many new entrants to avoid these risks as possible.
            </span>
          </span>
        ) : (
          <span className='main_from-author_text'>
            There were a couple of reasons why we set out to write this book. <br/>
            Firstly,<strong className='main_from-author_text-strong'> as experts who had been in DeFi (Decentralized Finance) since day one</strong>, we could see interest in it was growing and therefore lots of new entrants would be looking for trustworthy sources of information <br/>
            to learn from. <br/>
            <span className='main_from-author_text-padding'>
              Secondly, we knew from our own experiences that there are malicious actors waiting to prey on unsuspecting victims in DeFi and we wanted to help as many new entrants to avoid these risks as possible.
            In writing the book, we started out by focusing on yield farming because this was the area of DeFi that was catching most people's attention. We also knew that it was crucial to share our knowledge about auditing smart contracts and assessing DeFi projects. <br/>
            As the book developed, we also realised how important it was to explain the fundamentals of DeFi to any readers who had never heard of it or never participated in crypto before.
            </span>
          </span>
        )}

        {more && <button className='main_from-author_reed' onClick={() => setMore(false)}>Read More <ArrowIcon/></button>}
      </div>

      <div className="main_defi">
        <div className='main_defi-img'>
          <img src={photo} alt="photo"/>
        </div>
        <div className="main_defi-text">
          <div className="main_defi-title">DeFi Bible Used at MIT University</div>
          <div className="main_defi-dop">MIT Cambridge</div>
          <div className="main_defi-desc">Alex Mashinsky Founder of Celsius Network and Yida Gao Founder of Shima Capital, during a Web3 Course at MIT Cambridge, endorses the DE.FI Best Seller Amazon book "Wall <br/> Street Era is Over". Used during University lesson.🚀
          </div>
        </div>
      </div>

      <div className="main_some">
        <div className="main_some-title">Some of the Secrets Revealed in the Book</div>
        <div className="main_some-items">
          <div className="main_some-item">4 steps how
            to <span>DYOR </span>in DeFi</div>
          <div className="main_some-item">
            How to <span>Safely Earn High Yield in </span> Defi
          </div>
          <div className="main_some-item">
            How DeFi protocols <span>earn revenue</span>
          </div>
          <div className="main_some-item">
            How we made <span>Several Million </span> DOLLARS in Defi
          </div>
          <div className="main_some-item">
            More than <span>40 Projects</span> Audited
          </div>
          <div className="main_some-item">
            <span>3 Rugpools </span> identified
          </div>
        </div>

        <div className="main_defiyield">
          <div className="main_defiyield-left">
            <div className="main_defiyield-title">WHAT’s DE.FI?</div>
            <div className="main_defiyield-img"><BigLogo /></div>
          </div>
          <div className="main_defiyield-text">
            <div className="main_defiyield-desc">
              DE.FI is leading as the safest cross chain multi asset Management dashboard in the DeFi space right now. First of it's kind to support both EVM and non EVM chains, with 24 chains integrated, giving users access to over 100 Yield Farming protocols.
                <br/> <br/>
              Also offering state of the art security tools that deliver a risk profile of all your wallets and accounts from one seamless easy to use dashboard.
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Main;
