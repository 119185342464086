import React from 'react';
import './style.scss'
import { AmazonIcon, GooglePlayIcon } from '../../assets/icons';
import book from '../../assets/book.png'

const BookFooter = ({active}) => {
  return (
    <div className='book-footer'>
      <div className="container">
        <div className="book-footer_content">
          <div className="book-footer_content-img">
            <img src={book} alt="book"/>
          </div>
          <div className="book-footer_content-buttons">
            <a target='_blank' href='https://play.google.com/store/books/details/The_Wall_Street_Era_is_Over_The_Investor_s_Guide_t?id=It9MEAAAQBAJ' className='link'><GooglePlayIcon/> Read in Google play</a>
            <a target='_blank' href='https://www.amazon.com/Wall-Street-Over-Cryptocurrency-Decentralized/dp/1527295362/' className='link'><AmazonIcon/> Get on Amazon</a>
            <div className="book-footer_button-wrapper">
              <a
                target='_blank'
                href={active === 0 ? 'https://www.amazon.com/Wall-Street-Over-Cryptocurrency-Decentralized/dp/1527295362/' : 'https://www.amazon.com/Wall-Street-Over-Cryptocurrency-Decentralized-ebook/dp/B094X2BJH9'}
                className='book_big-button'>Get Your Copy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookFooter;
